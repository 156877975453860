<template>
  <section class="policies-container">
    <s-container>
      <div class="policies-container__header">
        <button class="back" @click.prevent="back">
          <img :src="backIcon" alt="Voltar" />
        </button>
        <h1 class="policies-container__title">
          <strong>{{ $t('POLICIES_HOME.TITLE') }}</strong>
        </h1>
      </div>

      <div class="policies-container__content">
        <div v-if="loadingError">
          <md-snackbar
            class="bg-error top"
            md-position="center"
            :md-active.sync="showSnackbar"
            md-persistent
          >
            <span>
              {{ $t('POLICIES_HOME.LISTING_ERROR') }}
            </span>
          </md-snackbar>
        </div>

        <div v-else>
          <div v-for="policy in policies" :key="policy.id">
            <OptionCard
              :color="getColor(policy.status)"
              @next-step="onNextStep(policy.id)"
            >
              <template #icon>
                <img :src="getIcon(policy.status)" alt="" />
              </template>
              <template #content>
                <p>{{ policy.title }}</p>
                <p>{{ $t(getDescription(policy.status)) }}</p>
              </template>
            </OptionCard>
          </div>
        </div>
      </div>
    </s-container>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import SContainer from '@/components/ui/s-container'
import backIcon from '@/assets/images/issues/back_eco_icon.svg'
import OptionCard from '@/components/OptionCard'
import otherPotencialConflitInterestIcon from '@/assets/images/company_root/disclosure.svg'
import readIcon from '@/assets/images/policy/read_icon.svg'
import unreadIcon from '@/assets/images/policy/unread_icon.svg'

export default {
  name: 'PoliciesHome',

  components: {
    SContainer,
    OptionCard,
  },

  data() {
    return {
      backIcon,
      otherPotencialConflitInterestIcon,
      policies: [],
      loadingError: false,
      showSnackbar: false,
    }
  },

  async mounted() {
    try {
      this.policies = await this.getAvaiableCompanyDocuments()
    } catch (error) {
      this.loadingError = true
      this.showSnackbar = true
    }
  },

  methods: {
    ...mapActions(['getAvaiableCompanyDocuments']),

    getIcon(status) {
      return status === 'READ' ? readIcon : unreadIcon
    },

    getColor(status) {
      return status === 'READ' ? 'grey' : 'black'
    },

    getDescription(status) {
      return status === 'READ'
        ? 'POLICIES_HOME.READ_DESCRIPTION'
        : 'POLICIES_HOME.UNREAD_DESCRIPTION'
    },

    onNextStep(policyId) {
      this.$router.push(`policies/${policyId}`)
    },

    back() {
      this.$router.go(-2)
    },
  },
}
</script>

<style scoped>
.policies-container {
  padding-top: 15px;
}
.policies-container__title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
}

.policies-container__content {
  padding: 20px 40px;
}
.back {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
}
</style>
