<template>
  <button
    :class="['card', color, disabled]"
    @click.prevent="$emit('next-step')"
  >
    <div class="card__icon">
      <slot name="icon"></slot>
    </div>
    <div class="card__content">
      <slot name="content"></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'OptionCard',

  props: {
    color: {
      type: String,
      default: 'green',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.card {
  display: flex;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--shadow-card);
  border-radius: 20px;
  gap: 16px;
  padding: 24px 12px;
  margin-bottom: 25px;
  text-align: left;
  border: none;
  cursor: pointer;
}

.card:hover {
  text-decoration: none;
}

.card__icon img {
  max-width: none;
}

.card__content p {
  color: var(--color-grey-default);
  font-size: 14px;
  margin: 0;
}

.card__content p:first-child {
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 8px;
}

.card.green .card__content p:first-child {
  color: var(--color-green-default);
}

.card.blue .card__content p:first-child {
  color: var(--color-blue-default);
}

.card.grey .card__content p {
  color: var(--color-grey-lighter);
}

.card.black .card__content p {
  color: var(--color-grey-default);
}
</style>
